import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/containers/about/about.component';
import { TeamMemberCardComponent } from './components/containers/about/team/team-members-list/team-member-card/team-member-card.component';
import { TeamMembersListComponent } from './components/containers/about/team/team-members-list/team-members-list.component';
import { TeamComponent } from './components/containers/about/team/team.component';
import { DatasetComponent } from './components/containers/dataset/dataset.component';
import { DatasetsComponent } from './components/containers/datasets/datasets.component';
import { HomeComponent } from './components/containers/home/home.component';
import { NewsCardComponent } from './components/containers/home/news/news-list/news-card/news-card.component';
import { NewsListComponent } from './components/containers/home/news/news-list/news-list.component';
import { NewsComponent } from './components/containers/home/news/news.component';
import { ServicesComponent } from './components/containers/home/services/services.component';
import { ServiceComponent } from './components/containers/service/service.component';
import { DatasetPreviewCardComponent } from './components/shared/dataset-preview/dataset-preview-list/dataset-preview-card/dataset-preview-card.component';
import { DatasetPreviewListComponent } from './components/shared/dataset-preview/dataset-preview-list/dataset-preview-list.component';
import { DatasetPreviewToolbarComponent } from './components/shared/dataset-preview/dataset-preview-list/dataset-preview-toolbar/dataset-preview-toolbar.component';
import { DatasetPreviewComponent } from './components/shared/dataset-preview/dataset-preview.component';
import { FooterContactsComponent } from './components/shared/footer/footer-contacts/footer-contacts.component';
import { FooterNavbarComponent } from './components/shared/footer/footer-navbar/footer-navbar.component';
import { FooterSupportersComponent } from './components/shared/footer/footer-supporters/footer-supporters.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { NavbarComponent } from './components/shared/header/navbar/navbar.component';
import { PartnerCardComponent } from './components/shared/partners/partners-list/partner-card/partner-card.component';
import { PartnersListComponent } from './components/shared/partners/partners-list/partners-list.component';
import { PartnersComponent } from './components/shared/partners/partners.component';
import { ItemCardComponent } from './components/shared/preview-list/item-card/item-card.component';
import { PreviewListComponent } from './components/shared/preview-list/preview-list.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { DropdownComponent } from './components/ui/dropdown/dropdown.component';
import { InputComponent } from './components/ui/input/input.component';
import { HamburgerComponent } from './components/ui/mobile-menu/hamburger/hamburger.component';
import { MobileMenuComponent } from './components/ui/mobile-menu/mobile-menu.component';
import { DatasetFilterPipe } from './pipes/dataset-filter.pipe';
import { ContentTypeBadgeComponent } from './components/shared/content-type-badge/content-type-badge.component';
import { VacanciesComponent } from './components/containers/about/vacancies/vacancies.component';
import { ProjectComponent } from './components/containers/project/project.component';
import { JDSComponent } from './components/containers/jds/jds.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NavbarComponent,
    DatasetPreviewComponent,
    DatasetPreviewListComponent,
    DatasetPreviewCardComponent,
    ButtonComponent,
    PartnersComponent,
    PartnersListComponent,
    PartnerCardComponent,
    ServicesComponent,
    PreviewListComponent,
    ItemCardComponent,
    NewsComponent,
    NewsListComponent,
    NewsCardComponent,
    FooterComponent,
    FooterNavbarComponent,
    FooterContactsComponent,
    FooterSupportersComponent,
    AboutComponent,
    TeamComponent,
    TeamMembersListComponent,
    TeamMemberCardComponent,
    MobileMenuComponent,
    HamburgerComponent,
    DatasetComponent,
    DatasetsComponent,
    DatasetPreviewToolbarComponent,
    DropdownComponent,
    InputComponent,
    ContentTypeBadgeComponent,
    ServiceComponent,
    DatasetFilterPipe,
    VacanciesComponent,
    ProjectComponent,
    JDSComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
