import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-footer-contacts',
  templateUrl: './footer-contacts.component.html',
  styleUrls: ['./footer-contacts.component.scss'],
})
export class FooterContactsComponent {
  constructor(
    private env: EnvironmentService
  ) {}

  get shortAboutUs(): string {
    return this.env.texts.shortAboutUs;
  }

  get email(): string {
    return this.env.texts.email;
  }
}
