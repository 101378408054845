import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import MenuItem from '../model/MenuItem';
import AbstractRestService from './abstract-rest-service.interface';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class MenuStore extends AbstractRestService<MenuItem> {
  constructor(http: HttpClient, env: EnvironmentService) {
    super(http, env.menuItemsFetchUrl);
  }
}
