import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';
import { PartnerAboutStore } from 'src/app/services/partner-about.service';

@Component({
  selector: 'app-jds',
  templateUrl: './jds.component.html',
  styleUrls: ['./jds.component.scss'],
})
export class JDSComponent {
  constructor(
    public partnerStore: PartnerAboutStore,
    private env: EnvironmentService
  ) { }

  get jds(): string {
    return this.env.texts.jds;
  }
}
