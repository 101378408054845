<div class="footer-contacts">
  <div class="footer-contacts__email">
    <a href="mailto:{{this.email}}">{{this.email}}</a>
    <a
      class="footer-contacts__twitter"
      href="https://twitter.com/JewishHeritageN"
      target="_blank"
      rel="noopener noreferrer"
      title="Twitter"
    >
      <!-- <img src="assets/img/icons/twitter-blue.svg" alt=""/> -->
    </a>
  </div>
  <div class="footer-contacts__address" [innerHTML]="shortAboutUs">

  </div>
</div>
