import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  constructor(private env: EnvironmentService
    ) {}


  get headline() {
    return this.env.texts.newsHeadline;
  }
}
