import { Component, ElementRef, ViewChild } from '@angular/core';
import { ServiceStore } from 'src/app/services/service.service';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  @ViewChild('element') elementRef: ElementRef;

  constructor(private store: ServiceStore,
    private env: EnvironmentService
    ) {}

  get services() {
    return this.store.getAll();
  }

  get headline() {
    return this.env.texts.servicesHeadline;
  }
}

