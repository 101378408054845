<div class="navbar">
  <div class="brand">
    <a routerLink="/">
      <img src="assets/img/logos/logo-white.svg" alt="Jewish Heritage Network" />
    </a>
  </div>
  <nav class="nav-menu">

      <a class="nav-menu__item" routerLinkActive="nav-menu__item_active" routerLink="{{menuItem.link}}"
      title="{{menuItem.text}}" *ngFor="let menuItem of menuItems | async">{{menuItem.text}}</a>
  
    <a class="nav-menu__item" title="Contact us" (click)="navItemClickHandler('footer')">Contact</a>
    <a class="nav-menu__item nav-menu__item_twitter" href="https://twitter.com/JewishHeritageN" target="_blank"
      rel="noopener noreferrer" title="Twitter">
    </a>
  </nav>
</div>