import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuStore } from 'src/app/services/menu.service';
import MenuItem from 'src/app/model/MenuItem';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  menuItems: Observable<MenuItem[]>;

  constructor(
    private router: Router,
    private store: MenuStore) {}

  ngOnInit(): void {
    this.menuItems = this.store.getAll();
    console.log("Menu items: " + JSON.stringify(this.menuItems));
  }

  async navItemClickHandler(anchor: string, route: string = undefined) {
    if (route) {
      await this.router.navigate([route]);
    }

    setTimeout(() => {
      // TODO refactor to be Angular-like
      document
        .querySelector(`#${anchor}`)
        .scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }
}

