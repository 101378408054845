<div class="footer-navbar">
  <div class="footer-navbar__logo">
    <a routerLink="/">
      <img src="assets/img/logos/logo-black.svg" alt="Jewish Heritage Network" />
    </a>
  </div>
  <div class="footer-navbar-menu">
    <a
      routerLink="/datasets"
      class="footer-navbar-menu__item"
      routerLinkActive="footer-navbar-menu__item_active"
      title="Dataset Gallery"
      >Datasets</a
    >
    <a class="footer-navbar-menu__item" [routerLink]="['/services']"
      >Services</a
    >
    <a
      routerLink="/about"
      class="footer-navbar-menu__item"
      routerLinkActive="footer-navbar-menu__item_active"
      >About</a
    >
  </div>
</div>
