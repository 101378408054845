<main class="service" *ngIf="service">
  <div class="service-header">
    <app-header></app-header>
    <div class="service-header_intro">
      <div class="component-content">
        <div class="service__title" [innerHTML]="service.title"></div>
        <div class="service__description" [innerHTML]="service.description"></div>
      </div>
      <div class="service-mockup-wrapper">
        <img [src]="service.imageUrl" [alt]="service.title" />
      </div>
    </div>
    <section class="service-features">
      <div class="component-content">
        <div class="service-stripe__title"></div>
        <div class="service-features-list__item-description" [innerHTML]="service.long_description"></div>
        <div class="service-features-list">
          <div class="service-features-list__item" *ngFor="let feature of service.features">
            <div class="service-features-list__item-icon">
              <img [src]="feature.iconImageUrl" />
            </div>
            <div class="service-features-list__item-title" [innerHTML]="feature.title"></div>
            <div class="service-features-list__item-description" [innerHTML]="feature.description"></div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="service-usecases">
    <div class="component-content">
      <div class="service-stripe__title"></div>
      <app-preview-list [items]="service.useCases"></app-preview-list>
      <div class="service-needs">
        <div class="service-needs__title">
          Is this service perfect for your needs?
        </div>
        <app-button preset="blue"><a href="mailto:info@jhn.ngo">Get in touch with us</a></app-button>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</main>